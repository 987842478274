import { Component, ElementRef, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  Validators,
  UntypedFormGroup,
  UntypedFormArray,
} from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ViewChild } from "@angular/core";
import { Clients } from "../../../models/clients";
import { Category } from "../../../models/category";
import { UntypedFormControl } from "@angular/forms";
import { CategoryService } from "../../../services/categories.service";
import { ConfirmationService, MessageService, SelectItem } from "primeng/api";
import * as moment from "moment";
import {
  FISCAL_CODE_VALIDATOR_REGEX,
  SEX_LIST,
  STATE_LIST,
  BUSINESS_STATE_LIST,
  STATE_LIST_USER,
} from "../../constants/constants";
import { ComuniService } from "src/app/services/comuni.service";
import { Comuni } from "src/app/models/comuni";
import { Location } from "@angular/common";
import { AppointmentsService } from "src/app/services/appointments.service";
import { Appointments } from "src/app/models/appointments";
import { BillingsService } from "src/app/services/billings.service";
import { Billings } from "src/app/models/billings";
import { PersonalDataService } from "src/app/services/personal_data.service";
import { Personal_data } from "src/app/models/personal_data";
import { NgxSpinnerService } from "ngx-spinner";
import { Province } from "src/app/models/province";
import { ProvinceService } from "src/app/services/province.service";
import { SectorService } from "src/app/services/sector.service";
import { Sector } from "src/app/models/sector";
import { Observable } from "rxjs";
import { UsersService } from "src/app/services/users.service";
import { Users } from "src/app/models/users";
import { StartupsService } from "src/app/services/startups.service";
import { Startups } from "src/app/models/startups";
import { ProfilesService } from "src/app/services/profiles.service";
import { StartupsLikesService } from "src/app/services/startups_likes.service";
import { Startups_likes } from "src/app/models/startups_likes";

@Component({
  selector: "app-users-form",
  templateUrl: "./users-form.component.html",
})
export class UsersFormComponent implements OnInit {
  @ViewChild("myInput", { static: false }) myInputVariable: ElementRef;

  pageTitle: string;
  error: string;
  uploadError: string;
  imagePath: any;
  clients: Clients;
  user: Users;

  clientsList: any = [];
  clientList: Clients;

  categories: any = [];
  category: Category;
  checked: boolean = true;
  selectedValue: string;
  comuni: Comuni;
  provinceList: Province[] = [];
  sectorList: Sector[] = [];
  pageSize = 10;
  user_id: any;
  like_from_ip: any;
  pageSizes = [5, 10, 15];
  blogForm: UntypedFormGroup;
  typeList: any[];
  count = 0;
  countLikes = 0;

  cities: Clients[];
  format1: string = "";
  format2: string = "";
  selectedCity: Clients;
  selectedCategories: Category;
  selectedDate: Date;
  date: Date;
  appointments: any = [];
  is_featured = "0";
  deleteButton: boolean;
  billings: any = [];
  billing: Billings;
  currentUser: any;
  addForm: UntypedFormGroup;
  rows: UntypedFormArray;
  itemForm: UntypedFormGroup;
  skillsForm: UntypedFormGroup;
  skillsValues: any = [];
  stateOptions: any[];
  cols: any[];
  exportColumns: any[];
  _selectedColumns: any[];
  businessStateOptions: any[];
  personal_datas: any = [];
  personal_data: Personal_data;
  personName: string;
  mapOptions: any = [];
  infoContent = "";
  markers: any = [];
  zoom: string;
  activeStatus: boolean;
  previews: string[] = [];
  imageInfos?: Observable<any>;
  marker: any;
  newsletterAccept: string;
  startups: Startups[];
  likes: Startups_likes[];
  page = 1;
  profiles: Users[];

  trackByFn(index, item) {
    return item.id;
  }

  constructor(
    private fb: UntypedFormBuilder,
    private usersService: UsersService,
    private messageService: MessageService,
    private personalDataService: PersonalDataService,
    private profilesService: ProfilesService,
    private provinceService: ProvinceService,
    private sectorsService: SectorService,

    private _location: Location,
    private appointmentsService: AppointmentsService,
    private billingsService: BillingsService,
    private startupsService: StartupsService,
    private startupsLikesService: StartupsLikesService,
    private spinner: NgxSpinnerService,

    private confirmationService: ConfirmationService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser") || "[]");
    this.stateOptions = STATE_LIST_USER;
    const userId = this.currentUser.user_id;
  }

  ngOnInit() {
    this.spinner.show();

    const id = this.route.snapshot.paramMap.get("id");

    if (id) {
      this.pageTitle = "Modifica User";

      this.usersService.getId(+id).subscribe((res) => {
        this.user = res;
        this.load(this.user);
        this.loadProfile(this.user);
        this.loadLikes(this.user);

        this.blogForm.patchValue({
          username: res.username,
          display_name: res.display_name,
          user_email: res.user_email,
          account_type: res.account_type,
          payment_expire: res.payment_expire,
          newsletter: res.newsletter,
          is_active: res.is_active,
          id: res.id,
        });

        this.newsletterAccept = this.user.newsletter;
      });
    } else {
      this.deleteButton = false;
      this.pageTitle = "Aggiungi User";
    }

    this.blogForm = this.fb.group({
      id: [""],
      username: ["", Validators.required],
      display_name: ["", Validators.required],
      user_email: [""],
      account_type: [""],
      payment_expire: [""],
      password: [""],
      newsletter: [""],
      is_active: ["0"],
    });

    this.spinner.hide();
  }

  activateAccount() {
    const id = this.blogForm.get("id").value;

    this.usersService.activeAccountEmail(+id).subscribe(
      (res) => {
        this.messageService.add({
          key: "myKey1",
          severity: "success",
          summary: "Informazioni",
          detail: "Saved successfully",
        });
      },
      (error) => (this.error = error)
    );
  }

  getSectorList() {
    this.sectorsService.getAllList().subscribe((data) => {
      this.sectorList = data;
    });
  }

  public handlePageChange(event): void {
    this.page = event;
    this.load(this.user);
  }

  load(user: Users): void {
    const params = this.getRequestParams(
      this.page,
      this.pageSize,
      (this.user_id = this.user.id),
      this.like_from_ip
    );
    this.startupsService.list(params).subscribe((pData) => {
      this.startups = pData;
      this.count = this.startupsService.size;
    });
  }

  loadLikes(user: Users): void {
    const paramsLikes = this.getRequestParams(
      this.page,
      this.pageSize,
      (this.like_from_ip = this.user.id),
      this.like_from_ip
    );
    this.startupsLikesService.list(paramsLikes).subscribe((pData) => {
      this.likes = pData;
      this.countLikes = this.startupsLikesService.size;
    });
  }

  loadProfile(user: Users): void {
    const params = this.getRequestParams(
      this.page,
      this.pageSize,
      (this.user_id = this.user.id),
      this.like_from_ip
    );
    this.profilesService.list(params).subscribe((pData) => {
      this.profiles = pData;
    });
  }

  getRequestParams(
    page: number,
    pageSize: string | number,
    user_id: string | number,
    like_from_ip: string | number
  ): any {
    // tslint:disable-next-line:prefer-const
    let params: any = {};
    let adder = "?";
    if (page) {
      params[`page`] = page - 1;
      adder + "page=" + (page - 1);
      adder = "&";
    }

    if (pageSize) {
      params[`size`] = pageSize;
      adder + "size=" + pageSize;
    }

    if (user_id) {
      params[`user_id`] = user_id;
      adder + "user_id=" + user_id;
    }

    if (like_from_ip) {
      params[`like_from_ip`] = like_from_ip;
      adder + "like_from_ip=" + like_from_ip;
    }

    return params;
  }

  getProvinceList() {
    this.provinceService.getAllList().subscribe((data) => {
      this.provinceList = data;
    });
  }

  getCategoryItem(category_id: string, id: string) {
    return this.categories.find((item) => item.id === category_id);
  }

  hasNoSelectedAppointments() {
    return (
      this.appointments.filter((appointment) => appointment.title).length === 0
    );
  }

  onDelete(id: number, title: string) {
    this.confirmationService.confirm({
      message: "Sei sicuro di volerlo cancellare",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.usersService.delete(+id).subscribe(
          (res) => {
            console.log(res);
            this.messageService.add({
              key: "cancel",
              severity: "success",
              summary: "Attenzione",
              detail: "Cancellazione avvenuto con successo",
            });
            this._location.back();
          },
          (error) => {
            this.error = error;
            this.messageService.add({
              key: "cancel",
              severity: "warn",
              summary: "Attenzione",
              detail: "Errore backend",
            });
          }
        );
      },
    });
  }

  onSubmit() {
    const formData = new FormData();
    formData.append("username", this.blogForm.get("username").value);
    formData.append("is_active", this.blogForm.get("is_active").value);
    formData.append("account_type", this.blogForm.get("account_type").value);
    formData.append("user_email", this.blogForm.get("user_email").value);
    formData.append("newsletter", this.blogForm.get("newsletter").value);
    formData.append(
      "payment_expire",
      this.blogForm.get("payment_expire").value
    );

    const id = this.blogForm.get("id").value;
    if (id) {
      console.log(this.blogForm.value);

      this.usersService.update(formData, +id).subscribe(
        (res) => {
          if (res.status == "error") {
            this.messageService.add({
              key: "myKey1",
              severity: "warn",
              summary: "Informazioni",
              detail: "Errore",
            });

            this.uploadError = res.message;
          } else {
            this.messageService.add({
              key: "myKey1",
              severity: "success",
              summary: "Attenzione",
              detail: "Salvato con sucesso",
            });
            this.ngOnInit();
            // this._location.back();
          }
        },
        (error) => (this.error = error)
      );
    } else {
      this.usersService.create(formData).subscribe(
        (res) => {
          if (res.status === "error") {
            this.uploadError = res.message;
          } else {
            this.messageService.add({
              key: "myKey1",
              severity: "success",
              summary: "Attenzione",
              detail: "Salvato con sucesso",
            });
            this.router.navigate(["/admin/users"]);
          }
        },
        (error) => (this.error = error)
      );
    }
  }
}

export function codFisc(c: UntypedFormControl): { [s: string]: boolean } {
  if (c.value && !c.value.match(FISCAL_CODE_VALIDATOR_REGEX)) {
    return { invalidCF: true };
  }
}
